<template>
  <v-navigation-drawer
    :key="addComponentKey"
    :value="isOrderVideoNewOpen"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="(val) => $emit('update:is-order-video-new-open', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <h4 class="font-weight-semibold text-base">
          {{ t('videos.add_order_video') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-order-video-new-open',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="form">
          <!-- facilities -->
          <!-- <v-select
            v-if="userData.role === 'A'"
            v-model="groupId"
            :label="t('organizations.organization')"
            :placeholder="t('organizations.organization')"
            :items="groupsOptions"
            item-text="name"
            item-value="id"
            outlined
            class="mb-1"
            readonly
            dense
            :append-icon="null"
          ></v-select> -->

          <!-- facilities -->
          <!-- <v-select
            v-model="facilityId"
            :label="t('facilities.facility')"
            :placeholder="t('facilities.facility')"
            :items="facilitiesOptions"
            item-text="name"
            item-value="id"
            outlined
            class="mb-1"
            readonly
            dense
            :append-icon="null"
          ></v-select> -->

          <!-- field -->
          <v-select
            v-model="addOrderData.field_id"
            :label="t('fields.field')"
            :placeholder="t('fields.field')"
            :items="fieldOptions"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            :rules="[required]"
            class="mb-1"
          />

          <v-menu
            v-model="modal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedVideoDate"
                :label="t('videos.date')"
                :placeholder="t('videos.date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                :rules="[required]"
                class="mb-1"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="addOrderData.video_date"
              color="primary"
              :max="maxDate"
              :min="minDate"
              :locale="computedLocale"
              @input="modal = false"
            ></v-date-picker>
          </v-menu>

          <v-autocomplete
            v-model="addOrderData.start_time"
            :label="t('videos.start_time')"
            :placeholder="t('videos.start_time')"
            :items="chunkOptions"
            item-text="start_time_text"
            item-value="start_time"
            outlined
            clearable
            dense
            :disabled="!addOrderData.field_id"
            :rules="[required]"
            class="mb-1"
          ></v-autocomplete>

          <v-select
            v-model="addOrderData.video_time"
            :label="t('video_details.duration')"
            :placeholder="t('video_details.duration')"
            :items="minuteOptions"
            item-text="text"
            item-value="value"
            outlined
            clearable
            class="mb-1"
            dense
            :rules="[required]"
          />

          <div class="d-flex flex-wrap justify-center">
            <v-btn
              color="secondary"
              class="me-3 my-2"
              rounded
              @click="comfirmAdd(addOrderData, addOrderData.video_time)"
            >
              {{ t('videos.add_order') }}
            </v-btn>
            <v-btn
              color="lightGray"
              rounded
              outlined
              class="my-2"
              @click="$emit('update:is-order-video-new-open',false)"
            >
              {{ t('tooltip.close') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="isDialogVisible"
      max-width="400px"
    >
      <v-card rounded="10">
        <v-card-text class="pa-0">
          <v-carousel
            v-if="videoPreOrder && videoPreOrder.thumbnails.length"
            height="220"
            width="auto"
            hide-delimiters
            show-arrows-on-hover
            cycle
            :show-arrows="false"
          >
            <v-carousel-item
              v-for="
                (item,
                 index)
                  in
                    videoPreOrder.thumbnails"
              :key="index"
              :src="item"
            >
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
        <v-card-text class="py-3 px-1">
          <v-list
            v-if="details.length"
            subheader
            dense
          >
            <v-list-item
              v-for="detail in details"
              :key="detail.title"
              dense
              style="min-height: 30px;"
            >
              <v-list-item-content class="py-0">
                <v-list-item-title v-text="t(`video_details.${detail.title}`)"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action class="ma-0 mb-1">
                <v-list-item-title
                  v-if="detail.title === 'group'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('organizations.organization')"
                    :items="groupFacilityOptions"
                    item-text="name"
                    item-value="id"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    readonly
                    :append-icon="null"
                    hide-details
                    @change="comfirmAdd(videoPreOrder, details[1].subtitle, detail.subtitle, details[2].subtitle)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="detail.title === 'duration'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('video_details.duration')"
                    :items="minuteOptions"
                    item-text="text"
                    item-value="value"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    hide-details
                    @change="comfirmAdd(videoPreOrder, detail.subtitle, details[0].subtitle, details[2].subtitle)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else-if="detail.title === 'video_type'"
                >
                  <v-select
                    v-model="detail.subtitle"
                    :placeholder="t('videos.video_type')"
                    :items="videoTypesOptions"
                    item-text="title"
                    item-value="value"
                    class="detail-select"
                    style="width: 180px;"
                    outlined
                    dense
                    hide-details
                    @change="comfirmAdd(videoPreOrder, details[1].subtitle, details[0].subtitle, detail.subtitle)"
                  >
                  </v-select>
                </v-list-item-title>
                <v-list-item-title
                  v-else
                  :class="detail.class ? detail.class : ''"
                  v-text="detail.subtitle"
                ></v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <p
            class="ma-0 text-xs text-center"
            style="font-size: 0.7rem !important;"
          >
            {{ t('videos.video_cost') }}
          </p>
        </v-card-text>
        <v-card-actions class="justify-center pb-3">
          <v-btn
            class="text-xs"
            color="secondary"
            dark
            width="40%"
            rounded
            @click="onNotify"
          >
            {{ t('tooltip.continue') }}
          </v-btn>
          <v-btn
            class="text-xs"
            color="lightGray"
            dark
            width="30%"
            rounded
            outlined
            @click="() => isOrderVideoNewOpen = false"
          >
            {{ t('tooltip.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <notify-players
      v-model="isDialogPhoneVisible"
      :required-organizer-phone="isRequiredPhone"
      :facility-phone-code="facilityPhoneCode"
      @openClose="openClose"
      @continue="onContinue"
    ></notify-players>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import {
  getChucksMinutesOrderVideo,
  createOrderVideo,
  createRecurringOrderVideo,
  getVideoPreorder,
  getGroupByFacility,
  getChucksMinutesAll,
} from '@api'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency, isDev, formatStdDate } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import useCryptoJs from '@core/utils/useCryptoJs'
import { DURATION_OPTIONS } from '@core/utils/reservation'
import useSelectOptions from '@/@core/utils/useSelectOptions'

import NotifyPlayers from '../NotifyPlayers.vue'

export default {
  components: {
    NotifyPlayers,
  },
  model: {
    prop: 'isOrderVideoNewOpen',
    event: 'update:is-order-video-new-open',
  },
  props: {
    isOrderVideoNewOpen: {
      type: Boolean,
      required: true,
    },
    groupsOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    facilitiesOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    fieldOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    dateFilter: {
      type: String,
      default: '',
    },
    chunkFilter: {
      type: String,
      default: '0',
    },
    fieldFilter: {
      type: Number,
      default: 0,
    },
    facilityFilter: {
      type: Number,
      default: 0,
    },
    groupFilter: {
      type: Number,
      default: 0,
    },
    timeSelected: {
      type: Number,
      default: 0,
    },
    imgPreview: {
      type: Array,
      default: () => {},
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const form = ref(null)
    const modal = ref(null)
    const { t } = useUtils()
    const { configObjFacility } = useSelectOptions()
    const { hasPermission } = usePermissions()

    const date = new Date().toISOString().substr(0, 10)
    const { userData } = useCryptoJs()
    const isDialogVisible = ref(false)
    const isDialogPhoneVisible = ref(false)
    const isRequiredPhone = ref(false)
    const addComponentKey = ref(0)
    const computedIsOpen = computed(() => props.isOrderVideoNewOpen)
    const computedChunkFilter = computed(() => (props.chunkFilter !== '0' ? props.chunkFilter : null))
    const computedDateFilter = computed(() => props.dateFilter)
    const computedFieldFilter = computed(() => props.fieldFilter)
    const computedTimeSelected = computed(() => props.timeSelected)
    const computedGroupFilter = computed(() => props.groupFilter)
    const computedFacilityFilter = computed(() => props.facilityFilter)
    const computedImgSelected = computed(() => props.imgPreview)
    const computedMinDate = computed(() => props.minDate)
    const computedMaxDate = computed(() => props.maxDate)
    const weekdayOptions = computed(() => [
      { text: t('weekdays.monday'), value: 0 },
      { text: t('weekdays.tuesday'), value: 1 },
      { text: t('weekdays.wednesday'), value: 2 },
      { text: t('weekdays.thursday'), value: 3 },
      { text: t('weekdays.friday'), value: 4 },
      { text: t('weekdays.saturday'), value: 5 },
      { text: t('weekdays.sunday'), value: 6 },
    ])

    const computedFields = computed(() => props.fieldOptions)
    const computedLocale = computed(() => t('locale'))
    const minuteOptions = ref(DURATION_OPTIONS)
    const videoPreOrder = ref(null)
    const details = ref([])
    const facilityPhoneCode = ref('')
    const groupId = ref(null)
    const facilityId = ref(null)
    const groupFacilityOptions = ref([])
    const chunkTime = ref(5) // time between chunk
    const addOrderData = ref({
      amount: null,
      comission: null,
      chunk_id: null,
      field_id: null,
      start_time: null,
      subtotal: null,
      username: userData.value.username,
      tax: null,
      video_date: null,
      video_time: null,
      video_type: null,
      group_id: null,
      make_recurring: false,
      weekdays: [],
      phone_numbers: [],
    })
    const computedVideoDate = computed({
      get: () => {
        if (addOrderData.value.video_date) return formatStdDate(addOrderData.value.video_date, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale)

        return null
      },
      set: val => {
        addOrderData.value.video_date = val
      },
    })
    const chunkOptions = ref([])
    const videoTypesOptions = computed(() => [
      { title: t('status.premium'), value: 'T' },
      { title: t('status.basic'), value: 'S' },
    ])

    const fetchChunks = async (fieldId, dateVideo) => {
      if (fieldId && dateVideo) {
        const chunks = await getChucksMinutesOrderVideo(fieldId, dateVideo, chunkTime.value)
        chunkOptions.value = chunks.data.map(e => ({ ...e, start_time_text: formatStdDate(e.start_time, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale) }))
        addOrderData.value.start_time = computedChunkFilter.value
      }
    }

    const forceRerender = () => {
      addComponentKey.value += 1
    }

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        forceRerender()
        setTimeout(async () => {
          if (computedFieldFilter.value) await fetchChunks(computedFieldFilter.value, computedDateFilter.value)
          if (computedFieldFilter.value) addOrderData.value.field_id = computedFieldFilter.value
          else addOrderData.value.field_id = computedFields.value.at(0).id
          addOrderData.value.group_id = computedGroupFilter.value
          addOrderData.value.start_time = computedChunkFilter.value
          addOrderData.value.video_date = computedDateFilter.value
          addOrderData.value.video_time = computedTimeSelected.value
          groupId.value = computedGroupFilter.value
          facilityId.value = computedFacilityFilter.value

          const resp = await getChucksMinutesAll(5)
          chunkOptions.value = resp.data.map(e => ({ ...e, start_time_text: formatStdDate(e.start_time, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale) }))
        }, 100)
      } else {
        isDialogVisible.value = false
        isDialogPhoneVisible.value = false
        details.value = []
        form.value.reset()
      }
    })

    const getClientFinalAmount = video => video.subtotal + video.tax // - video.discount

    const getGroupId = group => {
      if (group) return group
      if (userData.value && userData.value.role !== 'A') return userData.value.group_id
      if (userData.value && userData.value.role === 'A' && groupId.value) return groupId.value

      return 0
    }

    const openClose = val => {
      isDialogPhoneVisible.value = val
    }

    const comfirmAdd = async (video, videoTime, group, videoType = 'U') => {
      details.value = []
      isDialogVisible.value = false
      isDialogPhoneVisible.value = false
      if (form.value.validate()) {
        const response = await getVideoPreorder(getGroupId(group), video.field_id, video.video_date, video.start_time, videoTime, videoType)
        if (response.ok) {
          const responseGroups = await getGroupByFacility(facilityId.value)
          groupFacilityOptions.value = responseGroups.data

          videoPreOrder.value = response.data

          facilityPhoneCode.value = videoPreOrder.value.facility_country_code
          addOrderData.value.amount = videoPreOrder.value.amount
          addOrderData.value.subtotal = videoPreOrder.value.subtotal
          addOrderData.value.comission = videoPreOrder.value.comission
          addOrderData.value.tax = videoPreOrder.value.tax
          addOrderData.value.start_time = videoPreOrder.value.start_time
          addOrderData.value.video_time = videoPreOrder.value.video_time
          addOrderData.value.video_type = videoPreOrder.value.video_type
          addOrderData.value.group_id = videoPreOrder.value.group_id
          isRequiredPhone.value = videoPreOrder.value.order_phone_number === 'Y'

          // addOrderData.value.discount = videoPreOrder.value.discount

          details.value.push({
            subtitle: videoPreOrder.value.group_id,
            title: 'group',
          })

          details.value.push({
            subtitle: videoPreOrder.value.video_time,
            title: 'duration',
          })

          if (videoPreOrder.value.can_change_order_type) {
            details.value.push({
              subtitle: videoPreOrder.value.video_type,
              title: 'video_type',
            })
          }

          // subtitle: videoPreOrder.value.video_schedule,
          const [start, end] = videoPreOrder.value.video_schedule.split(' - ')
          details.value.push({
            subtitle: `${formatStdDate(start, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)} - ${formatStdDate(end, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}`,
            title: 'time',
          })

          details.value.push({
            subtitle: videoPreOrder.value.video_date_str,
            title: 'date',
          })

          details.value.push({
            subtitle: videoPreOrder.value.facility_name,
            title: 'facility',
          })

          details.value.push({
            subtitle: videoPreOrder.value.field_name,
            title: 'field',
          })

          details.value.push({
            subtitle: videoPreOrder.value.sport_name,
            title: 'sport',
          })

          details.value.push({
            subtitle: formatCurrency(videoPreOrder.value.subtotal),
            title: 'amount',
            class: '',
          })

          // if (videoPreOrder.value.discount) {
          //   details.value.push({
          //     subtitle: `(${formatCurrency(videoPreOrder.value.discount)})`,
          //     title: 'discount',
          //     class: 'success--text',
          //   })
          // }

          details.value.push({
            subtitle: `${formatCurrency(videoPreOrder.value.tax)}`,
            title: 'tax',
            class: '',
          })

          // if (videoPreOrder.value.comission) {
          //   details.value.push({
          //     subtitle: `(${formatCurrency(videoPreOrder.value.comission)})`,
          //     title: 'comission',
          //     class: 'success--text',
          //   })
          // }

          details.value.push({
            subtitle: formatCurrency(getClientFinalAmount(videoPreOrder.value)),
            title: 'final_amount',
            class: 'font-weight-bold',
          })

          isDialogVisible.value = true
        } else {
          error(response.message.text)
        }
      }
    }

    const onNotify = () => {
      isDialogVisible.value = false
      isDialogPhoneVisible.value = true
    }

    const onCreate = async () => {
      if (form.value.validate()) {
        // organizer_phone_number: addOrderData.value.organizer_phone_number,

        const dataBody = {
          amount: addOrderData.value.amount,
          comission: addOrderData.value.comission,
          chunk_id: addOrderData.value.chunk_id,
          field_id: addOrderData.value.field_id,
          start_time: addOrderData.value.start_time,
          subtotal: addOrderData.value.subtotal,
          username: userData.value.username,
          tax: addOrderData.value.tax,
          video_date: addOrderData.value.video_date,
          video_time: addOrderData.value.video_time,
          video_type: addOrderData.value.video_type,
          group_id: addOrderData.value.group_id,
          make_recurring: addOrderData.value.make_recurring,
          weekdays: addOrderData.value.weekdays,
          phone_numbers: addOrderData.value.phone_numbers,
        }

        const resp = await createOrderVideo(dataBody)
        let message = null
        if (resp.ok) {
          // if (addOrderData.value.organizer_phone_number) addOrderData.value.phone_numbers.push(addOrderData.value.organizer_phone_number)

          if (addOrderData.value.make_recurring === 'Y') {
            const recurringBody = {
              recurring_order: {
                group_id: addOrderData.value.group_id,
                facility_id: facilityId.value,
                field_id: addOrderData.value.field_id,
                user_id: userData.value.username,
                start_time: addOrderData.value.start_time,
                video_time: addOrderData.value.video_time,
                weekdays: addOrderData.value.weekdays,
                phone_numbers: addOrderData.value.phone_numbers,
              },
              video_date: addOrderData.value.video_date,
            }
            await createRecurringOrderVideo(recurringBody)
            message = resp.message
            emit('openCloseNew', false)
            success(message)
          } else {
            emit('openCloseNew', false)
          }
        } else {
          message = resp.message.text
          error(message)
        }
      }
    }

    const onContinue = data => {
      if (data) {
        // addOrderData.value.organizer_phone_number = data.organizer_phone_number

        addOrderData.value.make_recurring = data.make_recurring ? 'Y' : 'N'
        addOrderData.value.weekdays = data.weekdays
        addOrderData.value.phone_numbers = data.phone_numbers
        onCreate()
      }
    }

    const onMakeRecurring = e => {
      if (!e) addOrderData.value.weekdays = []
    }

    onMounted(async () => {
      forceRerender()

      if (isDev.value && minuteOptions.value.findIndex(e => e.value === 5) === -1) minuteOptions.value.unshift({ text: '5 Min', value: 5 })
    })

    return {
      form,
      modal,
      date,
      userData,
      isDialogVisible,
      isDialogPhoneVisible,
      isRequiredPhone,
      facilityPhoneCode,
      groupId,
      facilityId,
      minuteOptions,
      videoTypesOptions,
      addOrderData,
      details,
      videoPreOrder,
      addComponentKey,
      chunkTime,
      computedVideoDate,

      // computed
      computedChunkFilter,
      computedDateFilter,
      computedFieldFilter,
      computedGroupFilter,
      computedFacilityFilter,
      computedTimeSelected,
      chunkOptions,
      computedIsOpen,
      computedImgSelected,
      computedFields,
      computedLocale,
      computedMaxDate,
      computedMinDate,
      weekdayOptions,
      groupFacilityOptions,

      comfirmAdd,
      onNotify,
      onCreate,
      onMakeRecurring,
      fetchChunks,
      getClientFinalAmount,
      forceRerender,
      required,
      hasPermission,
      openClose,
      onContinue,

      // i18n
      t,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
