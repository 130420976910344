import {
  ref, computed, watch,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import {
  getFieldsByFacility,
  getChucksOrderVideo,
  getOrderVideos,
} from '@api'
import useSelectOptions from '@core/utils/useSelectOptions'
import { allChunks } from '@core/utils/order-videos'

export default function useOrderVideoList() {
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions()

  const videoListTable = ref([])
  const fieldOptions = ref([])
  const chunkOptions = ref(allChunks)
  const groupsOptions = ref([])
  const facilitiesOptions = ref([])

  const computedTableColumns = computed(() => [
    { text: t('fields.field').toUpperCase(), value: 'name' },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name' },
    { text: t('sports.sport').toUpperCase(), value: 'sport.name', sortable: false },
    { text: t('fields.pricing').toUpperCase(), value: 'pricing_type_str', sortable: false },
    { text: t('fields.hourly_pricing').toUpperCase(), value: 'hourly_pricing', sortable: false },

    { text: t('users.users').toUpperCase(), value: 'fullname' },
    { text: t('facilities.facility').toUpperCase(), value: 'email' },
    { text: t('users.role').toUpperCase(), value: 'role', sortable: false },
    { text: t('facilities.facility').toUpperCase(), value: 'facility.name', sortable: false },
    { text: t('status.status').toUpperCase(), value: 'status', sortable: false },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const totalVideoListTable = ref(null)

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const fieldFilter = ref(null)
  const timerFetchVideos = ref(null)
  const timerFetchChunks = ref(null)

  const dateFilter = ref(
    new Date().toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const minDate = ref(
    new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const maxDate = ref(
    new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )

  const chunkFilter = ref(null)
  const loading = ref(false)
  const options = ref({
    sortBy: ['username'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const page = ref(1)
  const perPage = ref(8)

  const visibleVideoListTable = ref([])

  // const visibleVideoListTable = computed(() => videoListTable.value.slice((page.value - 1) * perPage.value, page.value * perPage.value))

  const lengthPagination = computed(() => Math.ceil(totalVideoListTable.value / perPage.value))

  const getGroup = () => {
    if (userData.value && userData.value.role !== 'A') return userData.value.group_id
    if (configOrganization.value) return configOrganization.value

    return 0
  }

  // fetch data
  const fetchVideos = async () => {
    clearTimeout(timerFetchVideos.value)
    timerFetchVideos.value = setTimeout(async () => {
      const orderVideos = await getOrderVideos(
        getGroup(),
        configFacility.value ? configFacility.value : 0,
        fieldFilter.value ? fieldFilter.value : 0,
        dateFilter.value,
        chunkFilter.value ? chunkFilter.value : '0',
      )

      videoListTable.value = orderVideos.data
      totalVideoListTable.value = orderVideos.data.length

      // page.value = 1s
      visibleVideoListTable.value = videoListTable.value.slice(
        (page.value - 1) * perPage.value,
        page.value * perPage.value,
      )
    }, 200)
  }

  const fetchChunks = async (fieldId, dateVideo) => {
    clearTimeout(timerFetchChunks.value)
    timerFetchChunks.value = setTimeout(async () => {
      if (fieldId) {
        const chunks = await getChucksOrderVideo(fieldId, dateVideo)
        chunkOptions.value = chunks.data
      } else chunkOptions.value = allChunks
    }, 300)
  }

  const fetchFields = async facilityId => {
    let fields = null
    if (userData.value && userData.value.role !== 'A') {
      // fields = await getFieldsByFacility(userData.value.facility_id)

      if (facilityId) {
        fields = await getFieldsByFacility(facilityId)
      }
    } else if (facilityId) fields = await getFieldsByFacility(facilityId)
    else fields = [] // await getFields()

    if (fields && fields.ok) {
      fieldOptions.value = fields.data

      // fieldFilter.value = fieldOptions.value.at(0).id
      fieldFilter.value = null
    } else {
      fieldOptions.value = []
      fieldFilter.value = null
    }
  }

  watch([dateFilter, chunkFilter], async () => {
    // await fetchChunks(fieldFilter.value, dateFilter.value)
    videoListTable.value = []
    visibleVideoListTable.value = []
    totalVideoListTable.value = 0
    await fetchVideos()
  })

  watch([fieldFilter], async () => {
    // await fetchChunks(fieldFilter.value, dateFilter.value)
    // chunkOptions.value = []
    // chunkFilter.value = '0'
    await fetchVideos()
  })

  watch([configFacility], async () => {
    fieldOptions.value = []
    fieldFilter.value = 0
    await fetchFields(configFacility.value)
    await fetchVideos()
  })

  watch([configOrganization], async () => {
    await fetchVideos()
  })

  watch([page], () => {
    visibleVideoListTable.value = []
    visibleVideoListTable.value = videoListTable.value.slice(
      (page.value - 1) * perPage.value,
      page.value * perPage.value,
    )
  })

  return {
    userData,
    videoListTable,
    computedTableColumns,
    searchQuery,
    roleFilter,
    statusFilter,
    fieldFilter,
    chunkFilter,
    dateFilter,
    minDate,
    maxDate,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    groupsOptions,
    facilitiesOptions,
    fieldOptions,
    chunkOptions,
    visibleVideoListTable,
    page,
    perPage,
    configOrganization,
    configFacility,

    t,
    fetchVideos,
    fetchFields,
    fetchChunks,
    lengthPagination,
  }
}
